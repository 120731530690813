import Button from "../../Pages/LandingPage/components/Button";
import { plans } from "../../constants";
export default function PlanCards() {
  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="text-[40px] text-white text-center font-semibold">Choose Your Report</h2>
      <p className="text-winions-orange font-medium text-xl max-w-[600px] mb-24 mt-3 mx-3 text-center">
        Cancel anytime for Free, no strings attached
      </p>
      <div className="flex flex-wrap justify-center gap-12">
        <RegularPricingCard
          title={"Blue Buff"}
          price={"FREE"}
          features={["Receive 1 Report per Month"]}
          link={`/checkout/${plans.low.id}`}
        />
        <BestValuePricingCard
          title={"Rift Herald"}
          price={"$2.99/month"}
          features={["Receive 4 Reports per Month (Weekly)"]}
          link={`/checkout/${plans.mid.id}`}
        />
        <RegularPricingCard
          title={"Baron Nashor"}
          price={"$9.99/month"}
          features={["Receive 30 Reports per Month (Daily)"]}
          link={`/checkout/${plans.top.id}`}
        />
      </div>
    </div>
  );
}

const RegularPricingCard = ({ title, price, features, link }) => {
  return (
    <div className="bg-blue-500 rounded-lg border-4 border-[#D98D30] border-opacity-40 major-shadow h-fit p-6 flex flex-col gap-3 max-w-[290px]">
      <h1 className="text-2xl font-semibold text-white underline underline-offset-[16px] decoration-winions-orange mb-4">
        {title}
      </h1>
      <div className="flex flex-col mb-2 text-winions-orange">
        For the Casual Player that wants to improve
      </div>
      <h1 className="text-2xl text-white mb-2">
        <span className="text-xl">Price:</span> {price}
      </h1>
      <hr className="bg-winions-orange border-none h-[2px] mb-2"></hr>
      <ul className="flex flex-col gap-2 list-disc text-winions-orange font-normal text-md list-inside">
        {features.map((feature) => (
          <li key={feature} className="">
            {feature}
          </li>
        ))}
      </ul>
      <Button
        className="bg-[#D98D30] text-center rounded-3xl py-1.5 font-normal mt-5"
        to={link}
        text="Select Plan"
      />
    </div>
  );
};

const BestValuePricingCard = ({ title, price, features, link }) => {
  return (
    <div className="flex relative flex-col rounded-lg border-4 border-[#D98D30] border-opacity-40 major-shadow shadow-2xl h-fit pt-12 max-w-[290px]">
      <div className="absolute text-lg -top-1 -left-1 w-[103%] bg-[#D98D30] h-fit rounded-t-lg py-3 font-medium tracking-wide flex items-center justify-center text-black">
        Best Value
      </div>
      <div className="bg-blue-500 p-6 flex flex-col gap-3 rounded-lg">
        <h1 className="text-2xl font-semibold text-white underline underline-offset-[16px] decoration-winions-orange mb-4">
          {title}
        </h1>
        <div className="flex flex-col mb-2 text-winions-orange">
          Gain an understanding <br />
          Gain an understanding
        </div>
        <h1 className="text-2xl text-white mb-2">
          <span className="text-xl">Price:</span> {price}
        </h1>
        <hr className="bg-winions-orange border-none h-[2px] mb-2"></hr>
        <ul className="flex flex-col gap-2 list-disc text-winions-orange font-normal text-md list-inside">
          {features.map((feature) => (
            <li key={feature} className="">
              {feature}
            </li>
          ))}
        </ul>
        <Button
          className="bg-[#D98D30] text-center rounded-3xl py-1.5 font-normal mt-8"
          to={link}
          text="Select Plan"
        />
      </div>
    </div>
  );
};
