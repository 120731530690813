import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth.context";
import wrapPromiseWithToast from "../../utils/wrapPromiseWithToast";
import Input from "../Input";
import Select from "../Select";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [riotName, setRiotName] = useState("");
  const [step, setStep] = useState(1);
  const [region, setRegion] = useState("NA1");
  const [tagline, setTagline] = useState("");
  const { register } = useAuth();
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const navigate = useNavigate();
  const validateCreateAccountStep = async () => {
    const missingFields = [];
    if (!email) missingFields.push("Email");
    if (!password) missingFields.push("Password");
    if (!confirmPassword) missingFields.push("Confirm Password");

    if (missingFields.length) {
      toast.error(`Please fill in the following fields: ${missingFields.join(", ")}`);
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    setStep(2);
  };
  const onRegister = async () => {
    if (!recaptchaVerified) return;
    if (
      !email ||
      !password ||
      !confirmPassword ||
      !riotName ||
      !region ||
      !tagline
    ) {
      const missingFields = [];
      if (!riotName) missingFields.push("Summoner Name");
      if (!region) missingFields.push("Region");
      if (!tagline) missingFields.push("Tagline");

      toast.error(`Please fill in the following fields: ${missingFields.join(", ")}`);
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    await wrapPromiseWithToast(
      register(email, password, riotName, tagline, region),
      {
        pending: "Registering...",
        success: "Registered successfully!",
        error: (err) =>
          err.response?.data?.detail?.toString?.() ||
          err.reponse?.data?.details?.[0]?.toString?.() ||
          "Error registering!",
      }
    );
    setTimeout(() => {
      navigate("/plans");
    }, 0);
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      {step === 1 ? (
        <div className="flex flex-col justify-center gap-10 md:w-[516px]">
          <h1 className="text-white text-3xl font-semibold text-center">
            Create Account
          </h1>
          <div className="flex flex-col mt-1.5 gap-4">
            <Input
              label="Email"
              type="email"
              placeholder="loltyler1@twitch.tv"
              className="rounded-full bg-opacity-60 bg-gray-900"
              labelClassName="text-lg"
              containerClassName={"md:w-[516px]"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Password"
              type="password"
              placeholder="********"
              className="rounded-full bg-opacity-60 bg-gray-900"
              labelClassName="text-lg"
              containerClassName={"md:w-[516px]"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              label="Confirm Password"
              type="password"
              placeholder="********"
              className="rounded-full bg-opacity-60 bg-gray-900"
              labelClassName="text-lg"
              containerClassName={"md:w-[516px]"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center gap-10 md:w-[516px]">
          <h1 className="text-white text-3xl font-semibold text-center">
            Link Your Riot Account
          </h1>
          <div className="flex flex-col mt-1.5 gap-4">
            <div className="flex">
              <Input
                label="Riot Name"
                type="text"
                maxLength={16}
                className="rounded-full bg-opacity-60 bg-gray-900"
                labelClassName="text-lg"
                value={riotName}
                containerClassName={"w-full"}
                onChange={(e) => setRiotName(e.target.value)}
              />
            </div>
            <div className="flex justify-between w-full items-center">
              <Input
                label="Tagline"
                type="text"
                maxLength={5}
                className="rounded-full bg-opacity-60 bg-gray-900"
                labelClassName="text-lg"
                value={tagline}
                containerClassName={"w-[63%]"}
                onChange={(e) => setTagline(e.target.value)}
                prefix={
                  <p className="absolute left-4 top-2 mr-[20px] text-white opacity-100">
                    #
                  </p>
                }
              />
              <Select
                label="Region"
                type="text"
                className="rounded-full bg-opacity-60 bg-gray-900 flex-1"
                labelClassName="text-lg"
                value={region}
                containerClassName={"w-[33%]"}
                onChange={(e) => setRegion(e.target.value)}
              >
                <option value="BR1">BR1</option>
                <option value="EUN1">EUN1</option>
                <option value="EUW1">EUW1</option>
                <option value="JP1">JP1</option>
                <option value="KR">KR</option>
                <option value="LA1">LA1</option>
                <option value="LA2">LA2</option>
                <option value="NA1">NA1</option>
                <option value="OC1">OC1</option>
                <option value="TR1">TR1</option>
                <option value="RU">RU</option>
                <option value="PH2">PH2</option>
                <option value="SG2">SG2</option>
                <option value="TH2">TH2</option>
                <option value="TW2">TW2</option>
                <option value="VN2">VN2</option>
              </Select>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="mt-12 mb-4 w-full flex justify-center">
          <ReCAPTCHA
            sitekey="6LeDYyEpAAAAAImLmIJokox6RaOKy7DKMSeMqIDa"
            onChange={() => setRecaptchaVerified(true)}
            theme="dark"
          />
        </div>
      )}
      <div className="flex justify-center items-center mt-8 gap-4 text-white">
        {step === 2 ? (
          <button
            className="bg-yellow-600 flex justify-center items-center gap-2 px-8 py-2.5 rounded-2xl self-end flex-1 text-center h-[38.7px] font-medium text-gray-900"
            onClick={() => {
              setStep(1);
              setRecaptchaVerified(false);
            }}
          >
            <FaArrowLeft className="w-4 font-light" />
            Back
          </button>
        ) : null}
        {step === 1 ? (
          <button
            className="bg-yellow-600 flex justify-center items-center gap-2 px-8 py-2.5 rounded-2xl self-end flex-1 text-center h-[38.7px] font-medium text-gray-900"
            onClick={validateCreateAccountStep}
          >
            Continue
            <FaArrowRight className="w-4 font-light" />
          </button>
        ) : (
          <button
            className="bg-yellow-600 g-recaptcha flex justify-center items-center gap-2 px-8 py-2.5 rounded-2xl self-end flex-1 text-center h-[38.7px] font-medium text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onRegister}
            disabled={!recaptchaVerified}
          >
            Continue
            <FaArrowRight className="w-4 font-light" />
          </button>
        )}
      </div>
      <div className="flex justify-center items-center mt-8 gap-4 text-white">
        <Link className="text-sm py-2 px-4" to={"/login"}>Already Have an Account?</Link>
      </div>
    </div>
  );
}
