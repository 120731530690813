import { useLayoutEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Pages/LandingPage/components/Footer';
import Navbar from '../../Pages/LandingPage/components/Navbar';
import './layout.css';
import { useAuth } from '../../context/auth.context';

const authRoutes = ['/login', '/register'];
const requireAuthenticationRoutes = ['/user/profile', '/checkout/:id'];

// For Email Verification in the future
// const verificationRoutes = ['/verify/:token'];

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const isAuthRoute = authRoutes.some((route) => !!matchPath(route, pathname));
  const isRequireAuthenticationRoute = requireAuthenticationRoutes.some(
    (route) => !!matchPath(route, pathname)
  );
  useLayoutEffect(() => {
    if (user && !loading && isAuthRoute) {
      navigate('/');
    }
    if (!user && !loading && isRequireAuthenticationRoute) {
      navigate('/login');
    }
  }, [user, loading, navigate, isAuthRoute, isRequireAuthenticationRoute]);

  if (loading) {
    return (
      <div className="h-screen w-screen flex flex-col overflow-hidden justify-center items-center">
        <AiOutlineLoading3Quarters className="animate-spin h-14 w-14 text-primary-700 opacity-50" />
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className='texture-bg'>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
