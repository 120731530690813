import { Link } from 'react-router-dom';
import arrowRight from '../assets/icons/arrow-right.svg';
import missfortune from '../assets/landing-page/missfortune.png';
import ezreal from '../assets/landing-page/ezreal.png';

const Features = () => {
  return (
    <section id="features" className="mt-28 px-6 md:px-24 xl:px-44">
      <div className="flex flex-col items-center">
        <h2 className="text-3xl md:text-[40px] text-white font-semibold">
          Winions Recap
        </h2>
        <p className="text-winions-orange font-medium text-2xl max-w-[600px] mt-5 text-center">
          The difference between your next win or loss
        </p>

        <div className='mt-24'>

          <section id="row-1" className='flex flex-col md:flex-row justify-center gap-x-16 md:h-[450px] max-w-3xl'>
            <div className='min-w-[calc(50%-1.5rem)]'>
              <h3 className="text-white text-xl md:text-3xl font-semibold capitalize text-center md:text-left">
                A gameplan based on your stats, telling you how to improve
              </h3>
              <p className="text-winions-orange text-md md:text-xl font-normal mt-5">
                Paying for coaching and watching VODs are expensive and time-consuming. Winions Recap analyzes the data for you, and gives you a clear path to winning more games.
              </p>
            </div>
            <img className='min-w-[calc(50%-1.5rem)] h-[200px] md:h-auto mt-8 md:mt-0 object-none object-[50%_17%] md:object-top' src={missfortune} alt="" />
          </section>

          <section id="row-2" className='flex flex-col md:flex-row justify-center gap-x-16 mt-16 md:h-[450px] max-w-3xl'>
            <img className='hidden md:block w-[calc(50%-1.5rem)] object-none' src={ezreal} alt="" />
            <div className='min-w-[calc(50%-1.5rem)]'>
            <h3 className="text-white text-xl md:text-3xl font-semibold capitalize text-center md:text-left">
                OP.GG Doesn’t tell you how to Win, we do
              </h3>
              <p className="text-winions-orange text-md md:text-xl font-normal mt-5">
                These other stats sites are “cool”, but they don’t actually help you improve. The Winions Recap report gives you a direct game-plan on how to win your next game.
              </p>
            </div>
            <img className='md:hidden min-w-[calc(50%-1.5rem)] h-[200px] mt-8 md:mt-auto object-none object-[50%_17%]' src={ezreal} alt="" />
          </section>

        </div>

        <div className="mt-28">
          <Link
            to={'/register'}
            className="button-type-yellow-bg-large !text-base !py-5 !px-9 flex gap-3 items-center"
          >
            Start Winning
            <img src={arrowRight} alt="icon" />
          </Link>
        </div>

      </div>
    </section>
  );
};

export default Features;
