import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Paypal from "../../Components/NewDesign/Subscription/Paypal";
import { clientId, plans } from "../../constants";
import { useAuth } from "../../context/auth.context";
import UserService from "../../services/user.service";

export default function Checkout() {
  const [approved, setApproved] = useState(false);
  const [isFreeSubscribing, setIsFreeSubscribing] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const subscription = plans[Object.keys(plans).find((plan) => plans[plan].id === id)];

  const { user, refreshUser } = useAuth();

  useLayoutEffect(() => {
    if (
      user?.subscription_type &&
      (user?.subscription_type !== plans.low.id || id === plans.low.id)
    ) {
      navigate("/user/profile");
    }
  }, [user]);  // eslint-disable-line

  if (!subscription) {
    return (
      <div className="text-center text-3xl font-bold my-40">Invalid Plan</div>
    );
  }

  const onConfirm = (id) => {
    setApproved(true);
  };

  const freeSubscribe = () => {
    try {
      setIsFreeSubscribing(true);
      toast
        .promise(UserService.freeSubscribe(), {
          pending: "Subscribing...",
          success: "Subscribed",
          error: "Failed to subscribe",
        })
        .then(() => {
          refreshUser();
        });
    } finally {
      setIsFreeSubscribing(false);
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      {!approved ? (
        <div className="flex flex-col gap-4 items-center">
          {!user?.subscription_type ? (
            <div className="flex flex-col items-center gap-8">
              <h1 className="text-center text-white text-3xl">Checkout</h1>
              <div className="border-winions-orange border-4 p-4 rounded-2xl bg-blue-500">
                <h3 className="text-winions-orange font-medium text-md">
                  {subscription.name} Plan
                </h3>
                <div className="flex flex-col gap-4 w-60 text-white mt-6">
                  <p className="flex w-full justify-between">
                    Report Freq:
                    <span className="text-yellow-600">
                      {subscription.reportFreq}
                    </span>
                  </p>
                  <p className="flex w-full justify-between">
                    Price:
                    <span className="text-yellow-600">
                      ${subscription.price} + tax / month
                    </span>
                  </p>
                  <p className="flex w-full justify-between">
                    Next billing date:
                    <div className="text-yellow-600">
                      {new Date().toLocaleDateString()}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-3xl font-bold mb-1 text-white">
              <h1>
                {user?.subscription_type === id ? (
                  <>
                    You are already subscribed to this plan&nbsp;
                    <span className="text-yellow-600">{subscription.name}</span>
                  </>
                ) : user &&
                  user?.subscription_type &&
                  user?.subscription_type !== id ? (
                  <>
                    You are about to upgrade your
                    <br />
                    subcription to{" "}
                    <span className="text-yellow-600">{subscription.name}</span>
                  </>
                ) : null}
              </h1>
              {user?.subscription_type && (
                <p className="text-lg">Fee: {subscription.price} USD / Month</p>
              )}
            </div>
          )}
          <hr className="text-white w-full mb-3" />
          {user?.subscription_type !== id && (
            <div className="w-[350px] flex justify-center">
              {subscription.id === "FREE" ? (
                <button
                  onClick={freeSubscribe}
                  className="bg-yellow-600 shadow-lg py-1 px-4 rounded-lg"
                  disabled={isFreeSubscribing}
                >
                  Subscribe for free
                </button>
              ) : (
                <Paypal planId={id} clientId={clientId} freqString={subscription.freqString} onConfirm={onConfirm} />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="text-3xl font-bold mb-1 text-white text-center">
          <h1>Success!</h1>
          <h3>
            You are subscribed to:{" "}
            <span className="text-yellow-600">{subscription.name}</span>
          </h3>
        </div>
      )}
    </div>
  );
}
