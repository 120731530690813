import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/auth.context.jsx";
import wrapPromiseWithToast from "../../utils/wrapPromiseWithToast.js";
import Input from "../Input/index.jsx";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { confirmPasswordReset } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("t");

  useEffect(() => {
    const isValidJWT = (token) => {
      if (!token) return false;
      // JWTs have 3 separate parts to them, so this is checking if those exist, verifying the stucture of the token
      const parts = token.split('.');
      return parts.length === 3 && parts.every(part => !!part.trim());
    };
  
    if (!token || !isValidJWT(token)) {
      navigate("/login");
    }
  }, [token, navigate]);

  const onConfirmPasswordReset = async () => {
    await wrapPromiseWithToast(() => confirmPasswordReset(token, newPassword, confirmPassword), {
      pending: "Resetting password...",
      success: "Password reset successfully! You may now login with your new password.",
      autoClose: 5000,
      error: (err) =>
        err.response?.data?.detail?.toString?.() ||
        err.response?.data?.details?.[0]?.toString?.() ||
        "Error resetting password!",
    }).then(() => {
      navigate("/login");
    }).catch((err) => {
      console.log("Failed to reset password:", err);
    });
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <h1 className="text-white text-3xl font-semibold">Reset Password</h1>
      <div className="flex flex-col mt-8 gap-4 justify-center">
        <Input
          label="New Password"
          type="password"
          placeholder="********"
          className="rounded-full bg-opacity-60 bg-gray-900"
          labelClassName="text-lg"
          containerClassName={"md:w-[516px]"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          label="Confirm Password"
          type="password"
          placeholder="********"
          className="rounded-full bg-opacity-60 bg-gray-900"
          labelClassName="text-lg"
          containerClassName={"md:w-[516px]"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <div className="flex justify-center">
          <button
            className="bg-yellow-600 w-fit flex items-center justify-center gap-2 px-12 py-1.5 rounded-2xl text-center h-[38.7px] font-normal text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onConfirmPasswordReset}
          >
            Continue
            <FaArrowRight className="w-4 font-light" />
          </button>
        </div>
      </div>
    </div>
  );
}