import { useEffect, useState } from 'react';
import Hero from "./sections/Hero";
import SampleReport from "./sections/SampleReport";
import Features from "./sections/Features";
import HowItWorks from "./sections/HowItWorks";
import PlanCards from "../../Components/PlanCards";
import CookiesAlert from "./components/CookiesAlert";
import { useAuth } from "../../context/auth.context";
import { getItemWithExpiration } from '../../utils/localStorage';

const LandingPage = () => {
  const { user } = useAuth();
  const [showCookiesAlert, setShowCookiesAlert] = useState(false);

  useEffect(() => {
    const consentGiven = getItemWithExpiration('cookiesConsentGiven');
    if (!consentGiven && !user) {
      setShowCookiesAlert(true);
    }
  }, [user]);

  return (
    <div className="max-w-[1920px] mx-auto">
      <Hero />
      <SampleReport />
      <Features />
      <HowItWorks />
      <div className="flex mt-28 pb-48 w-full justify-center">
        <PlanCards />
      </div>
      {showCookiesAlert && <CookiesAlert onClose={() => setShowCookiesAlert(false)} />}
    </div>
  );
};

export default LandingPage;
