import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { clsxm } from "../../utils/clsxm";

export default function Input({
  className,
  labelClassName,
  containerClassName,
  type,
  prefix,
  ...props
}) {
  // for if type is password
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={clsxm("flex flex-col", containerClassName)}>
      <label className={clsxm("text-white text-[13px] mb-1", labelClassName)}>
        {props.label}
      </label>
      <div className="relative">
        {prefix ? prefix : null}
        <input
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          {...props}
          className={clsxm(
            "bg-primary-700 relative w-full border-yellow-600 border-2 text-white text-[13px] px-4 py-2 rounded-md focus:outline-none focus:border-primary-500",
            className
          )}
        />
        {type === "password" && (
          <FaEye
            className="absolute right-3 top-3 text-white"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
    </div>
  );
}
