const Button = ({ text, className, srcIcon, to, target }) => {
  return (
    <a
      className={`
        ${className} 
        ${srcIcon ? 'flex gap-3 items-center' : ''}
      `}
      href={to}
      target={target}
    >
      {text}

      {srcIcon && <img src={srcIcon} alt="icon" />}
    </a>
  );
};

export default Button;
