import { Link } from 'react-router-dom';
import arrowRight from '../assets/icons/arrow-right.svg';
import ExplanationCard from '../components/ExplanationCard';
import electrocute from '../assets/landing-page/electrocute_rune.png';
import conqueror from '../assets/landing-page/conqueror_rune.png';
import comet from '../assets/landing-page/comet_rune.png';

const HowItWorks = () => {
  return (
    <section id="how-it-works" className="flex justify-center mt-28 px-6 md:px-24 xl:px-44">
      <div className='flex flex-col items-center max-w-4xl'>
        <h2 className="text-3xl md:text-[40px] text-white font-semibold">
          How Does It Work?
        </h2>

        <div className='flex flex-col lg:flex-row gap-11 mt-24'>
          <ExplanationCard
            img={electrocute}
            title="Link your Riot Account"
            text="Automated retrieval of your data. Email report sent on a Monthly, weekly, or daily basis."
          />
          <ExplanationCard
            img={comet}
            title={`
              Get Your Gameplan
              on a Monthly, Weekly,
              or Daily Basis
            `}
            text="Instantly understand what to focus on. Real recommendations, delivered right to your email."
          />
          <ExplanationCard
            img={conqueror}
            title={`
              Check Your Email,
              Apply the Insights,
              and Win LP!
            `}
            text="Queue up for your next game. Follow your game-plan and climb the ranks!"
          />
        </div>

        <div className="mt-28">
          <Link
            to={'/register'}
            className="button-type-yellow-bg-large !text-base !py-5 !px-9 flex gap-3 items-center"
          >
            Join Today
            <img src={arrowRight} alt="icon" />
          </Link>
        </div>

      </div>

	</section>
  );
};

export default HowItWorks;
