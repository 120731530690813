const Button = ({ 
  text,
  className,
  srcIcon,
  to,
  target,
  onclick
}) => {
  const redditClick = () => {
    // Call the Reddit Pixel 'track' method when the button is clicked
    window.rdt('track', 'Custom', {customEventName:'ButtonClicked'});
  };

  return (
    <a 
      className=
      {`
        hover:transform hover:scale-[1.02] hover:translate-y-[-7px]
        transition-all duration-150 ease-in-out
        ${className}
        ${
          srcIcon ? 'flex gap-3 items-center' : ''
        }
      `}
      href={to}
      target={target || "_self"}
      onClick={onclick || redditClick}
    >
      {text}

      {srcIcon && (
        <img src={srcIcon} alt="icon"/>
      )}
    </a>
  )
}

export default Button