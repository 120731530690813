import React from 'react';
import Button from './Button';
import { setItemWithExpiration } from '../../../utils/localStorage';

const CookiesAlert = ({ onClose }) => {
  const handleClose = () => {
    const consentExpiration = 365 * 24 * 60 * 60 * 1000; // 365 days in milliseconds
    setItemWithExpiration('cookiesConsentGiven', 'true', consentExpiration);
    onClose();
  };

  return (
    <div className="fixed bottom-5 left-5 bg-gray-900 text-white p-5 rounded-lg shadow-lg flex flex-col space-y-2.5 w-[361px] h-[200px]">
      <h2 className="text-lg font-semibold">Our Website Uses Cookies</h2>
      <p className="text-sm text-gray-400">
        Our website uses cookies for essential functionality, like keeping signing in and registering. By continuing, you consent to this use, as outlined in our <span className="text-winions-orange font-semibold">Cookie Policy.</span>
      </p>
      <Button
        text="I Understand"
        className="bg-[#D98D30] text-gray-900 text-center rounded-3xl py-1.5 font-normal mt-5 hover:text-white cursor-pointer transition-colors duration-150 ease-in-out hover:scale-100 hover:translate-y-0"
        onclick={handleClose}
      />
    </div>
  );
};

export default CookiesAlert;