import "../../../style.css";

const ExplanationCard = ({ img, title, text }) => {
  const sentences = text.split(/(?<=[!?.])/);

  return (
    <div className="flex flex-col items-center w-[300px]">
      <img className="w-[200px] h-[200px]" src={img} alt="" />
      <h4 className="text-white text-2xl text-center font-semibold whitespace-pre-line mt-9">
        {title.trim()}
      </h4>
      <ul className="mt-9 pl-4 w-[85%] text-xl list-disc list-outside">
        {sentences.map((sentence, index) => (
          <li key={index} className="text-winions-orange custom-li">
            {sentence.trim()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExplanationCard;
