import React from 'react';
import rectangleVertical from '../assets/icons/rectangle-vertical.svg';
import { Link } from 'react-router-dom';

const DropDownMenu = ({isOpen})=>{

    const links = [
        { text: 'Features', link: '/features' },
        { text: 'Pricing', link: '/pricing' },
    ];
    
    return(
        <div className={`md:hidden px-6 md:px-24 xl:px-44 fixed z-50 bg-gray-900 w-screen h-auto top-[7vh] flex justify-center duration-300 transform ${isOpen ? 'translate-y-0' : '-translate-y-full'} transition-transform ease-out`}>
        <ul className="pb-6 pt-6">
        {links.map((link, index) => (
            <li key={index} className="text-white underline mb-4 flex gap-3 items-center">
                <img src={rectangleVertical} alt="icon"/>
                <Link to={link.link} className="text-white hover:text-white">{link.text}</Link>
            </li>
        ))}
            <Link className="hover:text-white button-type-yellow-bg-sm max-[568px]:px-40 max-[472px]:px-28 max-[376px]:px-16 max-[632px]:px-52 max-md:px-60">Register Today</Link>
        </ul>  
    </div>
    )
};


export default DropDownMenu;