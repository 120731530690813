import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { plans } from '../../constants';
import { useAuth } from '../../context/auth.context';
import UserService from '../../services/user.service';

export default function ManageProfile() {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  useLayoutEffect(() => {
    // if the user is not logged in, redirect to the landing page
    if (!user) {
      navigate('/');
    } // else if the user is logged in, but there is no subscription, redirect to the plans page
    else if (user && !user.subscription_type) {
      navigate('/plans');
    }
  }, [user, navigate]);

  const userSubscriptionKey =
    Object.keys(plans).find(
      (key) => plans[key].id === user?.subscription_type
    ) ?? '';

  const usersSubscription = plans[userSubscriptionKey];

  const handleSubscriptionCancellingAndReturningToFreePlan = async () => {
    toast.promise(
      async () => {
        await UserService.cancelSubscription();
        await UserService.freeSubscribe();
        refreshUser();
      },
      {
        pending: 'Cancelling Subscription...',
        success: 'Subscription Cancelled',
        error: 'Failed to cancel subscription'
      }
    );
  };

  const handleConfirm = (id) => {
    if (id === 'FREE') {
      handleSubscriptionCancellingAndReturningToFreePlan();
      return;
    }
    if (user?.subscription_type === plans.low.id) {
      navigate(`/checkout/${id}`);
      return;
    }
    toast.promise(
      async () => {
        const result = await UserService.reviseSubscription(id);
        console.log(result);

        window.location.href = result.links[0].href;
      },
      {
        pending: 'Changing Subscription...',
        error: 'Failed to change subscription'
      }
    );
  };

  const cancelSubcription = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?'))
      return;
    try {
      setIsCancelling(true);
      toast
        .promise(UserService.cancelSubscription(), {
          pending: 'Cancelling Subscription...',
          success: 'Subscription Cancelled',
          error: 'Failed to cancel subscription'
        })
        .then(() => {
          refreshUser();
        });
    } finally {
      setIsCancelling(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="h-dvh w-screen flex flex-col p-16 text-white py-36">
      <h1 className="text-4xl font-bold">Welcome {user?.riot_name}!</h1>
      <hr className="text-slate-200 mt-4 mb-8" />
      <h2 className="text-lg">
        Your Current Plan is{' '}
        <span className="text-yellow-600">{usersSubscription?.name}</span>
      </h2>
      <span>
        <button className="underline" onClick={() => setIsChangingPlan(true)}>
          Change Plan
        </button>
        <button
          className="underline ml-4"
          onClick={cancelSubcription}
          disabled={isCancelling}
        >
          Cancel Subscription
        </button>
      </span>

      {isChangingPlan && (
        <div className="mt-8">
          <h2 className="text-lg">Choose a new plan:</h2>
          <div className="flex gap-4 mt-4">
            {Object.keys(plans)
              .filter((key) => plans[key].id !== user?.subscription_type)
              .map((key) => {
                const plan = plans[key];
                return (
                  <div className="flex flex-col gap-2 items-center px-2 bg-yellow-600 rounded-lg shadow-md pt-8 pb-2 w-[150px]">
                    <h3 className="text-xl">{plan.name}</h3>
                    <h4 className="text-lg">
                      {plan.price !== '0'
                        ? `${plan.price} USD / Month`
                        : 'FREE'}
                    </h4>
                    <button
                      className="bg-primary-700 px-1 py-1 rounded-lg mt-6 hover:bg-slate-800 transition-colors"
                      onClick={() => {
                        window.confirm(
                          'Are you sure you want to Change plan?'
                        ) && handleConfirm(plan.id);
                      }}
                    >
                      Select
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
