let baseUrl;

if (window.location.hostname === "winions.gg") {
  baseUrl = "https://winions.gg/api";
} else if (window.location.hostname === "dev.winions.gg") {
  baseUrl = "https://dev.winions.gg/api";
} else {
  baseUrl = "http://localhost:8000";
}

export { baseUrl };

export const clientId = "ARppnnrRhrvMnzPRm7yVMxyPRuJYO1fq7IBrU4_zuQTPSf6RMEY-9aU-IHKNutZX7vzRPuHoJ1NbNczn";  // live
// export const clientId = "Ab1mmmikbBEC-eK9R1we4P-dtY-K8J0SGxtYqp-2whvjgZaySxLB09GQq6cmXOR7lbdLKK1NrWq6XW2B"  // sandbox

export const plans = {
  low: { 
    id: "FREE", 
    name: "Blue Buff", 
    price: "0", 
    freqString: "monthly",
    reportFreq: "1 Report Every Month" 
  },
  mid: {
    id: "P-77G35177KU3693217MVOGLLA",  // live
    // id: "P-3L715768FU181942MMVXNHHA",  // sandbox
    name: "Rift Herald",
    price: "2.99",
    freqString: "weekly",
    reportFreq: "1 Report Every Week",
  },
  top: {
    id: "P-1LR6006255379590VMVOGMDY",  // live
    // id: "P-5M524309EC601954YMVXNHCY",  // sandbox
    name: "Baron Nashor",
    price: "9.99",
    freqString: "daily",
    reportFreq: "1 Report Everyday",
  },
};
