import { useEffect } from 'react';
import { loadScript } from '@paypal/paypal-js';
import UserService from '../../../services/user.service';

export default function PaypalComponent({ clientId, planId, freqString, onConfirm }) {
  useEffect(() => {
    const renderPayPalButtons = () => {
      if (window.paypal) {
        renderButtons();
      } else {
        loadScript({ "client-id": clientId, vault: true, components: 'buttons', currency: 'USD' })
          .then(() => {
            renderButtons();
          })
          .catch(err => {
            console.error('Failed to load PayPal script:', err);
          });
      }
    };

    const renderButtons = () => {
      window.paypal.Buttons({
        style: {
            shape: 'pill',
            color: 'blue',
            layout: 'vertical',
            label: 'subscribe',
        },
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            plan_id: planId,
          });
        },
        onApprove: function(data, actions) {
          // Handle subscription approval
          const subscriptionId = data.subscriptionID;
          console.log('Subscription approved:', subscriptionId);
          UserService.createPaymentIntent(subscriptionId, freqString)
            .then(() => {
              onConfirm(subscriptionId);
            })
            .catch(error => {
              console.error('Error creating payment intent:', error);
              // Optionally, handle errors or display a message to the user
            });
        },
        onCancel: function(data) {
          console.log('Subscription cancelled', data);
          // Handle the subscription cancellation event
          // Optionally, update UI or state to reflect the cancellation
        },
        onError: function(err) {
          console.error('PayPal Button Error:', err);
          // Handle errors encountered during the subscription process
          // Optionally, display an error message to the user
        }
      }).render(`#paypal-button-container-${clientId}`);
    };

    renderPayPalButtons();

    return () => {
      // Cleanup: clear the PayPal button container to prevent errors on hot reloads in development mode
      const container = document.getElementById(`paypal-button-container-${clientId}`);
      if (container) {
        container.innerHTML = '';
      }
    };
  }, [clientId, planId, freqString, onConfirm]); // Re-run the effect if any of these dependencies change

  return <div id={`paypal-button-container-${clientId}`} className="flex-1"></div>;
}
