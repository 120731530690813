// function that stores a value into localStorage associated with an expiration time in milliseconds
export const setItemWithExpiration = (key, value, time) => {
  const now = new Date();
  const item = {
      value: value,
      expires: now.getTime() + time,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

// function to retrieve a value from localStorage and check if it is expired
export const getItemWithExpiration = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expires) {
      localStorage.removeItem(key);
      return null;
  }

  return item.value;
};