import React, { createContext, useContext, useEffect, useState } from "react";
import UserService from "../services/user.service";

const authContext = createContext({});

export const useAuth = () => useContext(authContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const { user, token } = await UserService.login(email, password);
      setUser(user);
      localStorage.setItem("token", token);
    } finally {
      setLoading(false);
    }
  };

  const register = async (email, password, riot_name, tagline, region) => {
    const { user, token } = await UserService.register(
      email,
      password,
      riot_name,
      tagline,
      region
    );
    setUser(user);
    localStorage.setItem("token", token);
  }; // TODO

  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  const requestPasswordReset = async (email) => {
    await UserService.requestPasswordReset(email);
  };

  const confirmPasswordReset = async (token, new_password, confirm_password) => {
    await UserService.confirmPasswordReset(
      token,
      new_password,
      confirm_password,
    );
  };

  const refreshUser = async () => {
    const user = await UserService.whoami();
    setUser(user);
  };

  const whoami = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        setLoading(true);
        const user = await UserService.whoami();
        setUser(user);
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    } catch (error) {
      localStorage.removeItem("token");
      setUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    whoami();
  }, []);

  return (
    <authContext.Provider
      value={{ user, confirmPasswordReset, login, logout, loading, register, refreshUser, requestPasswordReset }}
    >
      {children}
    </authContext.Provider>
  );
};
