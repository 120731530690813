import { clsxm } from "../../utils/clsxm";
export default function Select({
  className,
  labelClassName,
  containerClassName,
  type,
  prefix,
  children,
  ...props
}) {
  return (
    <div className={clsxm("flex flex-col", containerClassName)}>
      <label className={clsxm("text-white text-[13px] mb-1", labelClassName)}>
        {props.label}
      </label>
      <div className="relative">
        {prefix ? prefix : null}
        <select
          {...props}
          className={clsxm(
            "bg-primary-700 relative w-full border-yellow-600 border-2 text-white text-[13px] px-4 py-2 rounded-md focus:outline-none focus:border-primary-500",
            className
          )}
        >
          {children}
        </select>
      </div>
    </div>
  );
}
