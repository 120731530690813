import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/auth.context';
import arrowRight from '../assets/icons/arrow-right.svg';
import arrowDown from '../assets/icons/down-arrow.svg';
import { TextSwap } from '../../../Components/NewDesign/Animations';

const Hero = () => {
  const { user, loading } = useAuth();

  return (
    <main className="mt-10 px-6 md:px-24 xl:px-44 h-dvh flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-y-3">
        <h1 className="text-white font-semibold text-[35px] lg:text-[50px] text-center title-shadow max-w-3xl">
        A Stats Gameplan <br/>
        to Help You Win More {" "} <br />
        <TextSwap
          strings={["ARAM", "Ranked", "Draft Pick", "Blind Pick"]}
          animationType="slideDown"
          className="text-winions-orange text-nowrap"
        />
        {" "}Games
        </h1>

        <p className="text-winions-orange text-base max-w-xs lg:max-w-xl text-center mt-[26px]">
          Winions Recap is a personalized email report that gives you a detailed game plan on how
          to win your next game, using your in-game data.
        </p>

        <div className="flex gap-5 items-center mt-9 flex-wrap md:flex-nowrap justify-center">
          <button
            className="button-type-black-bg flex gap-3 items-center"
            onClick={() => {
              const features = document.getElementById('features');
              features?.scrollIntoView?.({ behavior: 'smooth' });
            }}
          >
            See what you're getting
            <img src={arrowDown} alt="icon" />
          </button>
          {!user && !loading && (
            <Link
              to={'/register'}
              className="button-type-yellow-bg-large !text-base !py-5 !px-9 flex gap-3 items-center"
            >
              Start Getting Insights
              <img src={arrowRight} alt="icon" />
            </Link>
          )}
        </div>
      </div>
    </main>
  );
};

export default Hero;
