import { Link } from 'react-router-dom';
import Button from '../../../Components/NewDesign/Button';
import { useAuth } from '../../../context/auth.context';
import hamburger from '../assets/icons/hamburger.svg';
import DropDownMenu from './DropDownMenu';
import { useState } from 'react';

const Navbar = () => {
  const { user, logout } = useAuth();
  const winionsLogoLink = '/';

  const [isOpen, setOpen] = useState(false);

  const toggleMenu=()=>{
    setOpen(!isOpen);
  }

  return (
    <>
    <div
      id="header"
      className="flex justify-center items-center lg:justify-between px-6 h-[7vh] min-h-[60px] lg:h-[8vh] w-screen md:px-24 xl:px-44 fixed top-0 z-50 bg-blue-500 shadow-[0_4px_25px_15px_rgba(0,0,0,0.25)]"
    >
      <Link
        className="text-2xl lg:text-[26px] text-center text-winions-orange cursor-pointer font-medium max-md:ml-auto"
        to={winionsLogoLink}
      >
        Winions.gg
      </Link>
      <div className="hidden gap-20 lg:flex lg:items-center">
        <Button
          text="Features"
          className="text-[18px] text-white hover:text-winions-orange hidden gap-10 lg:flex lg:items-center cursor-pointer scroll-smooth"
          to={'/#features'}
        />
        <Button
          text="Pricing"
          className="text-[18px] text-white hover:text-winions-orange hidden gap-10 lg:flex lg:items-center cursor-pointer"
          to={'/plans'}
        />
        {!user ? (
          <>
            <Button
              text="Sign in"
              className="text-[18px] text-white hover:text-winions-orange"
              to={'/login'}
            />
            <Button
              text="Register"
              className="button-type-yellow-bg text-[18px] px-7 py-2 hover:text-white transition-colors"
              to={'/register'}
            />
          </>
        ) : (
          <>
            {user && (
              <Button
                // instead of "My Profile", display the user's summoner name and tagline
                // e.g. loltyler1#NA1
                text={user.riot_name && user.tagline ? `${user.riot_name}#${user.tagline}` : "My Profile"}
                className="text-[18px] text-white hover:text-winions-orange hidden gap-10 lg:flex lg:items-center cursor-pointer"
                to={'/user/profile'}
              />
            )}
            <button
              className="text-[18px] text-white hover:text-winions-orange ms-auto cursor-pointer"
              onClick={logout}
            >
              Logout
            </button>
          </>
        )}
      </div>
      <button className="hidden cursor-pointer max-md:flex max-md:ml-auto"><img src={hamburger} onClick={toggleMenu} alt="icon"></img></button>
    </div>
    {isOpen && (<DropDownMenu isOpen={isOpen}/>) }
    </>
  );
};

export default Navbar;

