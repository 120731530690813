const Footer = () => {
  return (
    <footer className="bg-gray-900 shadow-[0_4px_25px_15px_rgba(0,0,0,0.25)] p-10 lg:h-28 w-full flex items-center">
      <div className=" flex flex-col justify-start lg:flex-row lg:items-center lg:px-16 gap-4">
        <h2 className="text-winions-orange text-[18px] md:text-[28px]">
          Winions.gg
        </h2>
        <p className="hidden lg:block text-[13px] md:text-[18px] text-white">
          © 2023
        </p>
        <p className="text-winions-orange text-[10px] lg:w-2/5 lg:ml-8">
          Winions.gg isn't endorsed by Riot Games and doesn't reflect the views or opinions
          of Riot Games or anyone officially involved in producing or managing
          Riot Games properties. Riot Games, and all associated properties are
          trademarks or registered trademarks of Riot Games, Inc.
        </p>
      </div>
    </footer>
  );
}

export default Footer