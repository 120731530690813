import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import bg from '../assets/landing-page/sample_report_bg.png';

const players = [
  { label: "Secillia#NA1", value: "Secillia,NA1,na1"},
  { label: "1T1T1T1T1T1#NA1", value: "1T1T1T1T1T1,NA1,na1"},
];

const dates = [];
const startDate = new Date('2024-02-15');
const endDate = new Date('2024-02-01');

for (let date = startDate; date >= endDate; date.setDate(date.getDate() - 1)) {
  const formattedDate = date.toISOString().split('T')[0];
  dates.push({ label: formattedDate, value: formattedDate });
}

const SampleReport = () => {
  const [selectedPlayer, setSelectedPlayer] = useState(players[0]); // Set initial state if necessary
  const [selectedDate, setSelectedDate] = useState(dates[0]); // Set initial state if necessary
  const [sampleReportURL, setSampleReportURL] = useState(''); // Initialize the URL state

  useEffect(() => {
    if (selectedPlayer && selectedDate) {
      const riotName = selectedPlayer.value.split(',')[0];
      const tagLine = selectedPlayer.value.split(',')[1];
      const region = selectedPlayer.value.split(',')[2];
      const url = `https://winions.gg/reports/${region}/${riotName}-${tagLine}/${selectedDate.value}/report.html`; // Generate the URL
      console.log(url);
      setSampleReportURL(url); // Update the URL state
    }
  }, [selectedPlayer, selectedDate]); // Depend on selectedPlayer and selectedDate

  return (
    <div
      className='max-w-[1920px] w-full h-80 md:h-64 flex justify-center items-center bg-center bg-no-repeat bg-cover'
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className='w-[90%] md:w-[500px] h-[90%] flex flex-col justify-center items-center gap-5 bg-blue-500/75 rounded-2xl'>
        <h2 className='text-xl md:text-3xl text-white text-center font-semibold'>
          See an Example
          <div className='hidden md:inline'> </div>
          <br className='md:hidden'/>
          Report
        </h2>

        <div className='flex flex-col md:flex-row items-center gap-6 md:gap-3'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-winions-orange'>for</p>
            <Select
              defaultValue={players[0]}
              onChange={setSelectedPlayer}
              options={players}
            />
          </div>

          <div className='flex flex-row items-center gap-3'>
            <p className='text-winions-orange'>on</p>
            <Select
              defaultValue={dates[0]}
              onChange={setSelectedDate}
              options={dates}
            />
          </div>
        </div>

        {/* Adjust the Link component to use the sampleReportURL */}
        <Link
          to={sampleReportURL} // Use the state variable here
          className="button-type-yellow-bg-large !text-base !py-2 !px-9 flex items-center"
          target="_blank"
        >
          View Report
        </Link>
      </div>
    </div>
  );
};

export default SampleReport;
