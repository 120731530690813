import React, { useEffect, useState, useRef } from "react";
import "./styles.css";

const buildAnimationStyle = (type, durationMs, widthTransitionDurationMs) => ({
  display: "inline-block",
  "text-wrap": "nowrap",
  animation: `${type} ${durationMs / 1000}s`,
  "animation-fill-mode": "forwards",
  transition: `width ${widthTransitionDurationMs / 1000}s ease`, // Convert milliseconds to seconds for CSS
});

// Additional style for the hidden span used to measure text width
const measureStyle = {
  visibility: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  top: "-9999px",
  left: "-9999px",
};

export const TextSwap = ({
  strings,
  interval = 2000, // Default interval in milliseconds
  animationType = "fade",
  animationDuration = 2100, // Default duration in milliseconds
  className = "",
  widthTransitionDuration = 500, // Default width transition duration in milliseconds
}) => {
  const animationStyle = buildAnimationStyle(animationType, animationDuration, widthTransitionDuration);
  const [currString, setCurrString] = useState(strings[0]);
  const [containerWidth, setContainerWidth] = useState("auto");
  const measureRef = useRef(null);

  // Measure and update width based on the current string
  useEffect(() => {
    if (measureRef.current) {
      setContainerWidth(`${measureRef.current.offsetWidth}px`);
    }
    
    const timer = setInterval(() => {
      const currIndex = strings.indexOf(currString);
      const nextIndex = currIndex < strings.length - 1 ? currIndex + 1 : 0;
      setCurrString(strings[nextIndex]);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [currString, strings, interval]);

  return (
    <>
      <div
        key={currString}
        style={{ ...animationStyle, width: containerWidth }}
        className={className}
      >
        {currString}
      </div>
      {/* Invisible span to measure text width */}
      <span ref={measureRef} style={measureStyle}>
        {currString}
      </span>
    </>
  );
};
